<template>
    <div>
      <Breadcrumbs main="" title="Carrier" class="showBreadcrumbs"/>
  
      <b-row class="mt-2">
        <b-col xl="12" md="12">
          <b-card title="" type="gradient-red" sub-title="" class="mb-4">
            <b-row class="align-items-start">
                <b-col class="col-md-6 col-12">
                  <span class="custome-title">Carrier</span>
                </b-col>
                <b-col class="col-md-6 col-12 d-flex justify-content-end align-items-start">
                  <b-form-group
                    label-cols="7"
                    label="Per page"
                    class="col-6 md-0 custom-font-18 text-right"
                    >
                    <b-form-select
                      class=" border custom-font-24"
                      v-model="perPage"
                      :options="pageOptions"
                      >
                    </b-form-select>
                  </b-form-group>
                  <button class="btn custom-btn" @click="OpenCarrier()">
                    Add Carrier</button>
                </b-col>
              </b-row>
  
            <b-row class="table-responsive datatable-vue">
              <b-table 
                class="text-left custom-font-16"
                :fields="carrierFields" 
                striped hover 
                :items="carrierData" 
                :busy="isBusy" 
                stacked="md" 
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                >
                <template #cell(country)="row">
                      <span v-if="countryOpt.filter(t=> t.id == row.item.country).length > 0">{{ countryOpt.filter(t=> t.id == row.item.country)[0].name }}</span>
                      <span v-else> {{ row.item.country }} </span>
                </template>
                <template #cell(status)="row">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                  </div>
              </template>
                <template #cell(action)="row">
                    <button
                      size="sm"
                      @click="EditCarrier(row.item)"
                      class="btn text-blue btn-xs mr-1"
                    >
                    <feather type="edit"></feather>
                    </button>
                    <button
                      size="sm"
                      @click="deleteCarrier(row.item._id)"
                      class="btn text-danger btn-xs"
                    >
                    <feather type="trash-2"></feather>
                    </button>
                  </template>
              </b-table>
            </b-row>
            <b-col class="mt-3 p-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0 custom-font-16"
              ></b-pagination>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
  
      <b-modal
        v-model="carrier_model"
        id="carrier-model"
        size="lg"
        title="Carrier"
        class="theme-modal"
        no-close-on-backdrop
        hide-footer
        @hide="getCarriers()"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-12">
              <b-card class="p-2">
                <form @submit.prevent="Submit()" action="">
                <div class="row">
                  <label>Name</label>
                  <b-form-input
                    v-model="input.name"
                    placeholder="Enter Name"
                    maxlength="200"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>Proxy</label>
                  <b-form-input
                    v-model="input.proxy"
                    placeholder="Enter Proxy"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>Port</label>
                  <b-form-input
                    v-model="input.port"
                    placeholder="Enter Port"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>Country</label>
                  <multiselect
                    :options="countryOpt"
                    label="name"
                    track-by="id"
                    v-model="countryArray"
                    placeholder="Select Country"
                    :multiple="false"
                    @input="manageCountry()"
                  >
                  </multiselect>
                </div>
                <div class="row mt-4 d-flex justify-content-center">
                  <b-button
                    type="submit"
                    class="col-md-3 col-12"
                    variant="primary"
                    :disabled="isBusy"
                    >{{ editCarrier?'Edit':'Submit'}}</b-button
                  >
                </div>
              </form>
              </b-card>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  
  
  
  <script>
  import CarrierApi from "../../../services/carrierService";
  import NetworkTypeService from '../../../services/NetworkTypeService'
  import Multiselect from "vue-multiselect";
  import config from "../../../services/globalLit";
  export default {
    name: "ServiceMapping",
    components: {
      Multiselect,
    },
    data() {
      return {
        countryOpt:[],
        input: {
          name: null,
          proxy: '',
          port: '',
          country:'',
        },
        countryArray:{},
        services: [],
        carrierData: [],
        carrier_model: false,
        isBusy: false,
        carrierFields: [
          { key: "name", label: "name" },
          { key: "proxy", label: "Proxy" },
          { key: "port", label: "Port", sortable: true },
          { key: "country", label: "Country", sortable: true },
          { key: "status", label: "Status", sortable: true},
          { key: "action", label: "Action"}
        ],
        editCarrier:false,
        totalRows: 0,
        filter: null,
        currentPage: 1,
        perPage: 20,
        pageOptions: [20, 50, 100, 200],
      };
    },
    mounted() {
      this.getCountry();
      this.getCarriers();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getCarriers() {
        this.input = {name: '',url: '',country: ""};
        let response = await CarrierApi.getCarriers();
        if (response.result) {
          this.carrierData = response.data;
          this.totalRows = this.carrierData.length;
        }
      },
      async Submit() {
        if(this.validate()){
        this.isBusy = true;
        var response
        var payload = {name: this.input.name,proxy: this.input.proxy,port: this.input.port}
        payload.country = this.countryArray.id
        if(this.editCarrier){
           payload  = {
            "cid":this.input._id,
            "updateObj": payload
          }
           response = await CarrierApi.editCarrier(payload);
           this.editCarrier = false;
        }else{
           response = await CarrierApi.addCarrier(payload);
        }
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            this.carrier_model = false;
            this.isBusy = false;
            this.getCarriers();
        }
        });
      }
      },
      manageCountry(){
        if(this.countryArray){
          this.input.country = this.countryArray.id
        }
      },
      validate(){
        if(this.input.port == '' || config.validatePort(this.input.port) == false){
          this.$toasted.show("Please enter valid port", {theme: 'outline',position: "bottom-center", type: "error", duration: 2000});
          return false;
        }
        if(this.input.country == ""){
          this.$toasted.show("Please select country", {theme: 'outline',position: "bottom-center", type: "error", duration: 2000});
          return false;
        }
        return true;
      },
      OpenCarrier() {
        this.input = {
          name: '',
          url: '',
          country: '',
          countryArray:[]
        };
        this.editCarrier = false;
        this.carrier_model = true;
      },
     async deleteCarrier(id){
      if(confirm("Are you sure you want to delete this carrier?")){
        let payload = {"cid":id}
        let response = await CarrierApi.deleteCarrier(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
        this.getCarriers();
        } 
      },
      EditCarrier(data){
        this.editCarrier = true;
        this.input = data;
        this.input.countryArray = [];
        this.countryArray = this.countryOpt.filter(t=> t.id == this.input.country)[0]
        this.carrier_model = true;
      },
      async getCountry(){
        let response = await NetworkTypeService.getCountry();
        this.countryOpt = [];
        response.data.forEach(element => {
          this.countryOpt.push({id:element.alpha_3,name:element.name})
        });
      },
      async changeStatus(data){
      if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
      let payload = {
        cid: data._id,
        updateObj: {
          status: data.status,
        },
      };
      let response = await CarrierApi.editCarrier(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
    },
    },
  };
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}
</style>